import { db } from "../../config/fbConfig";
import { ref, child, set, onValue, remove, push } from  'firebase/database'

const groupsRef = child(ref(db),'groups')
export const createGroup = (group) => {
  return (dispatch,getState) => {
      push(groupsRef,{
        ...group,
      }).then(()=>{
        dispatch({type: 'CREATE_GROUP',message:'success'});
      }).catch((err) => {
        dispatch({type: 'GROUP_ERROR'})
      })
  }
}

export const clearMessage = () => {
  return (dispatch,getState) => {
    dispatch({type: 'CREATE_GROUP',message:''})
  }
}


export const fetchGroups = () => {
  return (dispatch,getState) => {
    onValue(groupsRef, (ss) => {
      const groups = ss.exists ? ss.val() : {};
      dispatch({ type: 'GET_GROUPS', groups });
    });
  }
}

export const fetchGroup = (id) => {
  const groupRef = child(groupsRef,id)
  return (dispatch, getState) => {
    onValue(groupRef, (ss) => {
      const group = ss.exists ? ss.val(): {};
      dispatch({type: 'GET_GROUP',group:{[id]:group}})
    })
  }
}

export const filterGroups = (search) => {
  return (dispatch, getState) => {
    onValue(groupsRef , (ss)=>{
      const groups = Object.fromEntries(Object.entries(Object.values(ss.val())).filter(([eid,val])=> String(val.name+' '+val.description).toLowerCase().includes(search.toLowerCase())))
      dispatch({ type: 'GET_GROUPS', groups })
    })
  }
}

export const deleteGroup = (group,id) => {
  return (dispatch, getState) => {
    const removeRef = child(groupsRef,id);
    remove(removeRef).then(()=> {
      set(child(child(ref(db),'deletedGroups'),id),group).then(()=>{
        dispatch({type: 'DELETE_GROUP'})
      })
    }).catch((err) => {
      dispatch({ type: 'DELETE_GROUP_ERROR', err })
    })
  }
}
