import { getDownloadURL, ref as refs, uploadBytes } from "firebase/storage"
import { storage, db } from "../../config/fbConfig"
import { ref , child, set, onValue, push } from  'firebase/database'

const usersRef = child(ref(db),'users')
export const getVideoURL = (video) => {
    return (dispatch,getState) => {
        const urls = {};
        ['.webm','.mp4'].forEach(ext => {
            getDownloadURL(refs(storage,'videos/'+video+ext)).then(url => {
            urls[ext] = url;
            if(Object.keys(urls).length === 2){
                dispatch({type: 'VIDEO_URL', url:urls})
            }
            })
        })
    }
}

export const setStatus = (uid, id, status) => {
    const userRef = child(usersRef,uid);
    return (dispatch,getState) => {
        set(child(child(userRef,'status'),id),{...status}).then(()=>{
            dispatch({type:'SET_STATUS',status})
        }).catch((err) => {
            dispatch({ type: 'SET_STATUS_ERROR', err });
          })
    }
}

export const getStatus = (uid,id) => {
    const userRef = child(usersRef,uid);
    return (dispatch,getState) => {
        onValue(child(child(userRef,'status'),id),(ss)=>{
            const status = ss.exists() ? ss.val() : {currentModule: 0};
            dispatch({ type: 'GET_STATUS', status })
        })
    }
}

export const getCourses = (search) => {
    return (dispatch,getState) => {
        const coursesRef = child(ref(db),'courses');
        onValue(coursesRef,(ss)=>{
            const courses = ss.exists() ? ss.val() : {};
            const filteredCourses = Object.fromEntries(Object.entries(Object.values(courses)).filter(([id,val])=> String(val.name).toLowerCase().includes(search.toLowerCase())))
            dispatch({ type: 'GET_COURSES', list:filteredCourses })
        })
    }
}

export const getCourse = (id) => {
    return (dispatch,getState) => {
        const coursesRef = child(child(ref(db),'courses'),id);
        onValue(coursesRef,(ss)=>{
            const courses = ss.exists() ? ss.val() : {};
            dispatch({ type: 'GET_COURSES', list:{[id]:courses }})
        })
    }
}

export const createCourse = (course) => {
    const moduleList = [];
    const uploadFiles = (course) => {
        const { files } = course;
        const storageRef = refs(refs(storage, 'Courses'),course.id);
        const promises = [];

        files.forEach((file) => {
            const fileRef = refs(storageRef, file.name);
            const uploadTask = uploadBytes(fileRef, file);
            moduleList.push({title:file.name,fileName:fileRef.name,type:'video'});
            promises.push(uploadTask);
        });
        return Promise.all(promises);
    };
    return (dispatch, getState) => {
        const coursesRef = child(ref(db), 'courses');
        push(coursesRef, course).then((ref) => {
            set(child(child(coursesRef, ref.key), 'id'), ref.key).then(() => {
                uploadFiles({...course,id:ref.key}).then(() => {
                    set(child(coursesRef, ref.key), { ...course, modules: moduleList }).then(() => {
                        dispatch({ type: 'CREATE_COURSE', course });
                    });
                });
            }).catch((err) => {
                dispatch({ type: 'CREATE_COURSE_ERROR', err });
            });
        });
    };
}
