import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = (props) => {
  const { category } = props.auth;
  const location = useLocation();
  if(category==='LOGIN') {
    return <Navigate to='/signin' state={{from: location}} />
  }
  return <Outlet />
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(RequireAuth);