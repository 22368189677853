import { auth, functions } from "../../config/fbConfig";
import { setPersistence, browserSessionPersistence, signOut as so, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential, updatePassword, signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth'
import { httpsCallable } from "firebase/functions";

export const signIn = (credentials) => { 
  return (dispatch, getState) => {
    const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha');
    verifyRecaptcha({ token: credentials.captcha })
      .then((result) => {
        if (result.data.success) {
          setPersistence(auth, browserSessionPersistence)
            .then(() => {
              return signInWithEmailAndPassword(auth, credentials.email, credentials.password);
            })
            .then((obj) => {
              if (obj.user) {
                auth.currentUser.getIdTokenResult().then((idTokenResult) => {
                  dispatch({ type: 'LOGIN_SUCCESS', user: obj.user, category: idTokenResult.claims.category })
                }).catch((err) => {
                  dispatch({ type: 'LOGIN_ERROR', err: 'Invalid Credentials' })
                })
              } else {
                dispatch({ type: 'LOGIN_ERROR', err: 'Invalid Credentials' })
              }
            }).catch((err) => {
              dispatch({ type: 'LOGIN_ERROR', err: 'Invalid Credentials' })
            });
        } else {
          dispatch({ type: 'LOGIN_ERROR', err: 'reCAPTCHA verification failed' })
        }
      })
      .catch((error) => {
        dispatch({ type: 'LOGIN_ERROR', err: 'reCAPTCHA verification failed' })
      });
  };
};

export const reset = (email) => {
  const resetPassword = httpsCallable(functions,'resetPassword')
  return(dispatch, getState) => {
    resetPassword({email}).then(()=>{
      dispatch({type: 'RESET_SUCCESS'})
    }).catch((err)=>{
      dispatch({type: 'RESET_ERROR',err: "Reset Limit Exceeded, Contact Admin"})
    })
  }
}

export const signOut = () => {
  return (dispatch, getState) => {
    so(auth).then(() => {
      dispatch({type:'SIGNOUT_SUCCESS'});
      dispatch({type:'CLEAR_EMPLOYEES'});
    })
  }
}

export const stateChange  = () => {
  return (dispatch, getState) => {
    onAuthStateChanged(auth, user => {
      if(user) {
        auth.currentUser.getIdTokenResult().then((idTokenResult)=>{
          dispatch({type: 'LOGIN_SUCCESS', user , category:idTokenResult.claims.category})
        })
      } else {
        dispatch({type:'SIGNOUT_SUCCESS'});
      }
    })
  }
}

export const updateUserPassword = (password) => {
  const cred = EmailAuthProvider.credential(auth.currentUser.email,password.old)
  return(dispatch, getState) => {
    reauthenticateWithCredential(auth.currentUser,cred).then((cred)=>{
      updatePassword(auth.currentUser,password.new)
      so(auth)
    }).catch((err)=>{
      dispatch({type:'PASSWORD_UPDATE_FAILED',err})
    })
  }
}

export const clearError = () => {
  return (dispatch,getState) => {
    dispatch({type:'LOGIN_ERROR',err:''})
  }
}