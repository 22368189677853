import { useState, useEffect } from "react";
import { BrowserRouter,Routes, Route, Navigate } from "react-router-dom";
import NavigationBar from './layout/NavigationBar';
import Dashboard from "./dashboard/Dashboard";
import SignIn from './auth/SignIn';
import Reset from "./auth/Reset";
import RequireAuth from "./auth/RequireAuth";
import RequireAdmin from './auth/RequireAdmin';
import RequireSuperAdmin from './auth/RequireSuperAdmin';
import CoursePage from "./courses/CoursePage";
import CourseContent from "./courses/CourseContent";
import { Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { stateChange } from "../store/actions/authActions";
import UsersPage from "./users/UsersPage";
import CreateUser from "./users/CreateUser";
import RegisterUser from "./users/RegisterUser";
import RequireRegister from "./auth/RequireRegister";
import GroupsPage from "./groups/GroupsPage";
import CreateGroup from "./groups/CreateGroup";
import CreateCourse from "./courses/CreateCourse";


const App = (props) => {
  const { category } = props.auth
  const[loading,setLoading] = useState(true)
  useEffect(() => {
    props.stateChange();
    if(category){
      setLoading(false)
    }
  },[category])

  if(!loading){
    return(
      <BrowserRouter>
        <div className="App">
          {category !== 'LOGIN' ? <NavigationBar /> : ''}
          <Routes>
            <Route path='/signin' element={category && category !== 'LOGIN'? <Navigate to='/' replace /> : <SignIn />} />
            <Route exact path="/reset" element={category && category !== 'LOGIN'? <Navigate to='/' replace /> : <Reset />} />
            <Route element={<RequireAuth />}>
              <Route exact path='/register' element={<RegisterUser />} />
              <Route element={<RequireRegister />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/courses" element={<CoursePage />} />
                <Route exact path="/courses/create" element={<CreateCourse />} />
                <Route exact path="/course/:id" element={<CourseContent />} />
                <Route element={<RequireAdmin />}>
                  <Route exact path='/users' element={<UsersPage />} />
                  <Route exact path='/user/create' element={<CreateUser />} />
                  <Route element={<RequireSuperAdmin />}>
                    <Route exact path='/groups' element={<GroupsPage />} />
                    <Route exact path='/group/create' element={<CreateGroup />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </div>
        <div style={{ height: '100px' }}></div>
      </BrowserRouter>
    );
  } else {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    stateChange: () => dispatch(stateChange())
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps,mapDispatchToProps)(App);