import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createGroup, clearMessage, fetchGroups } from '../../store/actions/groupsActions'
import { Container, Form, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

const CreateGroup = (props) => {
  const [disabled,setDisabled] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false);reset();setDisabled(false)}
  const handleShow = () => setShow(true);
  const [message,setMessage] = useState('');
  const [duplicateGroup,setDuplicateGroup] = useState({})
  const [group,setGroup] = useState({
    name: '',
    description: ''
  })

  useEffect(()=>{
    if (props.groups && Object.keys(props.groups).filter((id) => props.groups[id].name===group.name.trim()).length){
      setMessage('Group Already Exist')
      setDuplicateGroup(props.groups[Object.keys(props.groups).filter((id) => props.groups[id].name===group.name.trim())])
    } else {
      setMessage('')
      setDuplicateGroup({})
    }
  },[group.name])
  useEffect(() => {
    if (props.groups && props.groups.message==='success') {
      setDisabled(false);
      props.clearMessage() 
    }
  }, [props.groups]);
  useEffect(()=>{
    props.fetchGroups();
  },[])

  const handleChange = (e) => {
    setGroup({ ...group,
      [e.target.id]: e.target.value
    })
  }

  const reset = () => {
    setGroup({
      name: '',
      description: '',
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    if(message === ''){
      props.createGroup(group);
      reset();
    } else {
      handleShow();
    }
  }
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Add Group</h3>
      <Form.Group as={Row} className="mb-3" controlId="name">
        <Form.Label className='fw-bold' column sm="2">Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={group.name} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="description">
        <Form.Label className='fw-bold' column sm="2">Description</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={group.description}/>
        </Col>
      </Form.Group>
      <Form.Group>
        <Button disabled={disabled} className='m-1' variant="success" type="submit">
          {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Create'}
        </Button>
        <Button className='m-1' variant="warning" onClick={()=> reset()}>
          Reset
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/groups')}>
          Cancel
        </Button>
      </Form.Group>
    </Form>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {duplicateGroup.name}</p>
          <p>Description: {duplicateGroup.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  </Container>
)}

const mapDispatchToProps = (dispatch) => {
  return {
    createGroup: (group) => dispatch(createGroup(group)),
    clearMessage: () => dispatch(clearMessage()),
    fetchGroups: () => dispatch(fetchGroups())
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
