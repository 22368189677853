import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createUser, batchCreateUsers, clearMessage, fetchUsers } from '../../store/actions/usersActions'
import { Container, Form, Row, Col, Button, Modal, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { fetchGroups, fetchGroup } from '../../store/actions/groupsActions';

const CreateUser = (props) => {
  const [disabled,setDisabled] = useState(false);
  const [importDisabled,setImportDisabled] = useState(false);
  const [importUsers,setImportUsers] = useState([])
  const [uploadFile,setUploadFile] = useState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false);reset();setDisabled(false)}
  const handleShow = () => setShow(true);
  const [message,setMessage] = useState('');
  const [duplicateUser,setDuplicateUser] = useState({})
  const [importGroup,setImportGroup] = useState(props.users && props.users[0] && props.users[0].group || '')
  const [user,setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    group: props.users && props.users[0] && props.users[0].group || ''
  })
  useEffect(()=>{
    props.fetchUsers('','ALL');
    if(props.auth.category === "SUPER_ADMIN")
      props.fetchGroups();
  },[])
  useEffect(()=>{
    if(props.auth.category === "ADMIN" && props.users && props.users[0] && props.users[0].group)
      props.fetchGroup(props.users[0].group);
    setUser({
      ...user,
      group: props.users && props.users[0] && props.users[0].group || ''
    })
  },[props.users])
  useEffect(()=>{
    if(props.users.message==='success')
      setDisabled(false)
      setImportDisabled(false)
      props.clearMessage()
  },[props.users.message])

  const fileReader = new FileReader();
  const csvFileToArray = string => {
    const csvHeader = ["Email","FirstName","LastName"];
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    const existingEmails = Object.keys(props.users).map(uid => props.users[uid].email);
    const uniqueEmails = array.filter((item, index, self) =>
      index === self.findIndex((t) => t.Email.trim() === item.Email.trim())
    );
    setImportUsers(uniqueEmails.filter((r) => r.Email.trim().length > 0 && !existingEmails.includes(r.Email.trim())));
  };

  useEffect(()=>{
    if (props.users && Object.keys(props.users).filter((uid) => props.users[uid].email===user.email.trim()).length){
      setMessage('User Already Exist')
      setDuplicateUser(props.users[Object.keys(props.users).filter((uid) => props.users[uid].email===user.email.trim())])
    } else {
      setMessage('')
      setDuplicateUser({})
    }
  },[user.email])

  const handleChange = (e) => {
    setUser({ ...user,
      [e.target.id]: e.target.value
    })
  }

  const groupChange = (e) => {
    setImportGroup(e.target.value)
  }

  const reset = () => {
    setUser({
      firstName: '',
      lastName: '',
      email: '',
      group: props.users && props.users[0] && props.users[0].group || 'choose'
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    if(message === ''){
      props.createUser(user);
      reset();
    } else {
      handleShow();
    }
  }

  const handleFileAttachment = (e) => {
    setUploadFile(e.target.files[0])
  }

  useEffect(()=>{
    if(uploadFile) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(uploadFile);
    }
  },[uploadFile])

  const removeRecord = (item) => {
    setImportUsers(importUsers.filter(r => r !== item))
  }
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
      <h3 className='grey-text text-darken-3'>Add User</h3>
      <Form.Group as={Row} className="mb-3" controlId="firstName">
        <Form.Label className='fw-bold' column sm="2">First Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={user.firstName} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="lastName">
        <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
        <Col sm="10">
          <Form.Control required onChange={handleChange} value={user.lastName}/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="email">
        <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
        <Col sm="10">
          <Form.Control required type='email' onChange={handleChange} value={user.email}/>
        </Col>
      </Form.Group>
        {props.groups ? <Form.Group as={Row} controlId="group">
        <Form.Label className='fw-bold' column sm="2">Group:</Form.Label>
        <Col sm="10">
          <Form.Select required id="group" onChange={handleChange} value={user.group}>
            <option value=''>Select Group</option>
            {Object.keys(props.groups).map((gId) => {
              return(
                <option key={gId} value={gId}>{props.groups[gId].name}</option>
              )
            })}
          </Form.Select>
        </Col>
      </Form.Group>: null } 
      <Form.Group>
        <Button disabled={disabled} className='m-1' variant="success" type="submit">
          {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Invite'}
        </Button>
        <Button className='m-1' variant="warning" onClick={()=> reset()}>
          Reset
        </Button>
        <Button className='m-1' variant="secondary" onClick={()=> navigate('/users')}>
          Cancel
        </Button>
      </Form.Group>
    </Form>
    <h3 className='grey-text text-darken-3'>Import Users</h3>
    <h4 className='grey-text text-darken-3'>Please upload a csv file with "Email, First Name, Last Name" as Column Header</h4>
    {props.groups ? <Form.Group as={Row} controlId="importGroup">
        <Form.Label className='fw-bold' column sm="2">Group:</Form.Label>
        <Col sm="10">
          <Form.Select required id="importGroup" onChange={groupChange} value={importGroup}>
            <option disabled hidden value=''>Select Group</option>
            {Object.keys(props.groups).map((gId) => {
              return(
                <option key={gId} value={gId}>{props.groups[gId].name}</option>
              )
            })}
          </Form.Select>
        </Col>
      </Form.Group>: null }
    <Form.Group controlId="formFile" className="mb-3">
      <Form.Label>Upload CSV File</Form.Label>
      <Form.Control type="file" accept='.csv' onChange={handleFileAttachment}/>
    </Form.Group>
    { importUsers.length ? 
      <>
      <Container className='m-3' style={{maxHeight: "400px", overflowY: "auto", padding: "15px"}}>
        <Table>
          <thead>
            <tr key={"header"}>
              <th className="text-center">Email</th>
              <th className="text-center">First Name</th>
              <th className="text-center">Last Name</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {importUsers.map((item) => (
              <tr key={item.id} className="allign-middle">
                {Object.values(item).map((val) => (
                  <td key={val}>{val}</td>
                ))}
                <td>
                  <span className="btn btn-danger m-1 rounded-circle modal-trigger" onClick={()=>removeRecord(item)}>
                    <i className="bi bi-trash"></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <h4>There are {importUsers.length} Unique Email IDs identified in the file uploaded</h4>
      <Button disabled={importDisabled} className='m-3' onClick={()=>{ setImportDisabled(true); props.batchCreateUsers(importUsers, importGroup)}}>
        {importDisabled ? <Spinner animation="border" size='sm' role="status" /> : `Invite ${importUsers.length}`}
      </Button>
      </>
    : '' }
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {duplicateUser.firstName} {duplicateUser.lastName}</p>
          <p>Email: {duplicateUser.email}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  </Container>
)}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(createUser(user)),
    batchCreateUsers: (importUsers, gid) => dispatch(batchCreateUsers(importUsers, gid)),
    clearMessage: () => dispatch(clearMessage()),
    fetchUsers: (search,status) => dispatch(fetchUsers(search,status)),
    fetchGroups: () => dispatch(fetchGroups()),
    fetchGroup: (id) => dispatch(fetchGroup(id))
  }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
