import { db, functions, auth } from "../../config/fbConfig";
import { ref, child, set, onValue, remove } from  'firebase/database'
import { httpsCallable } from 'firebase/functions'

const usersRef = child(ref(db),'users')
const groupsRef = child(ref(db),'groups')
export const createUser = (user) => {
  return (dispatch,getState) => {
    const sendInviteEmail = httpsCallable(functions, 'sendInviteEmailFunction');
    sendInviteEmail({
      email: user.email,
      name: user.firstName+' '+user.lastName
    }).then((result)=>{
      const userRef = child(usersRef,result.data.uid);
      const groupRef = child(groupsRef,`${user.group}/users`);
      set(userRef,{
        ...user,
        uid: result.data.uid
      }).then(()=>{
        onValue(groupRef, (ss) => {
          const users = ss.val() || [];
          users.push(result.data.uid);
          set(groupRef,users).then(()=>{
            dispatch({type: 'CREATE_USER',message:'success'});
          });
        }, {
          onlyOnce: true
        });
      }).catch((err) => {
        dispatch({type: 'USER_ERROR'})
      })
    })
  }
}

export const updateUser = (user) => {
  return (dispatch,getState) => {
    const userRef = child(usersRef,user.uid);
    onValue(userRef, (ss) => {
      if(ss.val().group !== user.group){
        const oldGroupRef = child(groupsRef,`${ss.val().group}/users`);
        const newGroupRef = child(groupsRef,`${user.group}/users`);
        onValue(oldGroupRef, (ss) => {
          const users = ss.val();
          const newUsers = ss.exists() ? users.filter(uid => uid !== user.uid) : [];
          set(oldGroupRef,newUsers).then(()=>{
            onValue(newGroupRef, (ss) => {
              const users = ss.val() || [];
              users.push(user.uid);
              set(newGroupRef,users).then(()=>{
                set(userRef,{
                  ...user
                }).then(()=>{
                  dispatch({type: 'UPDATE_USER'});
                });
              });
            },{
              onlyOnce: true
            })
          });
        }, {
          onlyOnce: true
        });
      } else {
        set(userRef,{
          ...user
        }).then(()=>{
          dispatch({type: 'UPDATE_USER'});
        });
      }
    }, {
      onlyOnce: true
    });
  }
}

export const registerUser = (user) => {
  return (dispatch, getState) => {
    const registerUser = httpsCallable(functions, 'registerUser');
    const userRef = child(usersRef, user.uid);
    set(userRef, {
      ...user
    }).then(() => {
      registerUser({uid: user.uid}).then(() => {
        auth.signOut();
        dispatch({ type: 'REGISTER_USER', message: 'success' });
      })
    }).catch((err) => {
      dispatch({ type: 'USER_ERROR' });
    });
  }
}

export const clearMessage = () => {
  return (dispatch,getState) => {
    dispatch({type: 'CREATE_USER',message:''})
  }
}

export const fetchUsers = (search,status) => {
  return (dispatch,getState) => {
    if(getState().auth.category === "SUPER_ADMIN"){
      onValue(usersRef, (ss) => {
        const users = ss.val();
        const filterUsers = Object.fromEntries(Object.entries(Object.values(users)).filter(([eid,val])=> String(val.firstName+' '+val.lastName+' '+val.email).toLowerCase().includes(search.toLowerCase())))
        switch(status){
          case 'NOT STARTED':
            const notStarted = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status === undefined || val.status.cyberSecurity.currentVideo === 0))
            dispatch({ type: 'GET_USERS', users:notStarted });
            break;
          case 'IN PROGRESS':
            const inProgress = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status && val.status.cyberSecurity && val.status.cyberSecurity.currentVideo !== 0 && !val.status.cyberSecurity.completed))
            dispatch({ type: 'GET_USERS', users:inProgress });
            break;
          case 'PASS':
            const pass = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status && val.status.cyberSecurity && val.status.cyberSecurity.completed))
            dispatch({ type: 'GET_USERS', users:pass });
            break;
          case 'FAIL':
            const fail = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status && val.status.cyberSecurity && val.status.cyberSecurity.retake && !val.status.cyberSecurity.completed))
            dispatch({ type: 'GET_USERS', users:fail });
            break;
          default:
            dispatch({ type: 'GET_USERS', users:filterUsers });
        }
      });
    } else if(getState().auth.category === "ADMIN"){
      const users = {};
      const userRef = child(usersRef,getState().auth.user.uid);
      onValue(userRef, (ss) => {
        const user = ss.val();
        const groupRef = child(groupsRef,`${user.group}/users`);
        onValue(groupRef, async (ss) => {
          const usersList = ss.val();
          const promises = usersList.map((uid) => {
            return new Promise((resolve, reject) => {
              const userRef = child(usersRef,uid);
              onValue(userRef, (ss) => {
                if(ss.exists)
                {
                  const user = ss.val();
                  users[uid] = user;
                }
                resolve();
              }, {
                onlyOnce: true
              });
            });
          });
          await Promise.all(promises);
          const filterUsers = Object.fromEntries(Object.entries(Object.values(users)).filter(([eid,val])=> val !== null && String(val.firstName+' '+val.lastName+' '+val.email).toLowerCase().includes(search.toLowerCase())))
          switch(status){
            case 'NOT STARTED':
              const notStarted = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status === undefined || val.status.cyberSecurity.currentVideo === 0))
              dispatch({ type: 'GET_USERS', users:notStarted });
              break;
            case 'IN PROGRESS':
              const inProgress = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status && val.status.cyberSecurity && val.status.cyberSecurity.currentVideo !== 0 && !val.status.cyberSecurity.completed))
              dispatch({ type: 'GET_USERS', users:inProgress });
              break;
            case 'PASS':
              const pass = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status && user.status.cyberSecurity && val.status.cyberSecurity.completed))
              dispatch({ type: 'GET_USERS', users:pass });
              break;
            case 'FAIL':
              const fail = Object.fromEntries(Object.entries(filterUsers).filter(([eid,val])=> val.status && user.status.cyberSecurity && val.status.cyberSecurity.repeat && !val.status.cyberSecurity.completed))
              dispatch({ type: 'GET_USERS', users:fail });
              break;
            default:
              dispatch({ type: 'GET_USERS', users:filterUsers });
          }
        }, {
          onlyOnce: true
        });
      }, {
        onlyOnce: true
      });
    }
  }
}

export const fetchUser = (uid) => {
  const userRef = child(usersRef,uid)
  return (dispatch, getState) => {
    onValue(userRef, (ss) => {
      const user = ss.val();
      dispatch({type: 'GET_USER',user})
    })
  }
}

export const deleteUser = (user) => {
  return (dispatch, getState) => {
    const removeRef = child(usersRef,user.uid);
    const deleteAccount = httpsCallable(functions,'deleteAccountFunction')
    remove(removeRef).then(()=> {
      deleteAccount({uid:user.uid}).then(()=>{
        set(child(child(ref(db),'deletedUsers'),user.uid),user).then(()=>{
          const groupRef = child(groupsRef,`${user.group}/users`);
          onValue(groupRef, (ss) => {
            const users = ss.val() || [];
            const updatedUsers = users.filter(uid => uid !== user.uid);
            set(groupRef, updatedUsers).then(() => {
              dispatch({type: 'DELETE_USER'})
            });
          });
        });
      })     
    }).catch((err) => {
      dispatch({ type: 'DELETE_USER_ERROR', err })
    })
  }
}

export const batchCreateUsers = (userList,groupId) => {
  return (dispatch,getState) => {
    const sendBatchInviteEmail = httpsCallable(functions, 'sendBatchInviteEmailFunction');
    sendBatchInviteEmail({userList,groupId}).then((result)=>{
      dispatch({ type: 'CREATE_USER',message:'success'});
    }).catch((err) => {
      dispatch({type: 'USER_ERROR',err})
    })
  }
}