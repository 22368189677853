import React from "react";
import AdminLinks from './AdminLinks'
import { connect } from "react-redux";
import { Container, Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import EmployeeLinks from "./EmployeeLinks";
import { signOut } from "../../store/actions/authActions";
import SuperAdminLinks from "./SuperAdminLinks";

const NavigationBar = (props) => {
  const { category,user } = props.auth;
  const links = (category==='SUPER_ADMIN') ? <SuperAdminLinks /> : (category==='ADMIN') ? <AdminLinks /> : category==='USER' ? <EmployeeLinks /> : '' ;
  return(
    <Navbar variant="light" expand='lg' sticky="top" style={{marginBottom:'8px', backgroundColor: 'lightskyblue'}}>
      <Container>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Navbar.Brand>
            <img
              alt="logo"
              src={require('../../assets/Navbar Logo.png')} 
              width={'20%'}
              height={'20%'}/>
          </Navbar.Brand>
          <Navbar.Brand>
            <img
              alt="second logo"
              src={require('../../assets/mozilor.png')} 
              width={'50%'}
              height={'50%'}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        </div>
        <Navbar.Collapse className="justify-content-end ms-auto" id="basic-navbar-nav">
          <Nav>
            {links}
            {category && category!=='LOGIN' ?
              <NavDropdown align="end" className="dropdown-menu-start"
                  title={<OverlayTrigger placement="bottom" overlay={<Tooltip className="m-2" id="tooltip">{user.email}</Tooltip>}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                          </svg>
                        </OverlayTrigger>}
                  id="basic-nav-dropdown">
              <NavDropdown.Item onClick={props.signOut}>
                <i className="bi bi-box-arrow-in-right" /> Logout
              </NavDropdown.Item>
              </NavDropdown> : null 
            }
          </Nav> 
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavigationBar);