import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { registerUser, fetchUser } from '../../store/actions/usersActions';
import { Container, Form, Button, Spinner } from 'react-bootstrap';

const RegisterUser = (props) => {
    const { uid, email } = props.auth.user;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [disabled, setDisabled] = useState(false);
    useEffect(()=>{
        props.fetchUser(uid);
    },[])
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleManagerEmailChange = (e) => {
        setManagerEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        setDisabled(true);
        e.preventDefault();
        const user = {
            firstName,
            lastName,
            managerEmail,
            uid,
            startDate: new Date().toLocaleDateString(),
            email,
            group: props.users.group,
        };
        props.registerUser(user);
    };

    return (
        <Container>
            <h2>User Registration</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="firstName">
                    <Form.Label>First Name:</Form.Label>
                    <Form.Control required type="text" value={firstName} onChange={handleFirstNameChange} />
                </Form.Group>
                <Form.Group controlId="lastName">
                    <Form.Label>Last Name:</Form.Label>
                    <Form.Control required type="text" value={lastName} onChange={handleLastNameChange} />
                </Form.Group>
                <Form.Group controlId="managerEmail">
                    <Form.Label>Reporting Manager Email:</Form.Label>
                    <Form.Control required type="email" value={managerEmail} onChange={handleManagerEmailChange} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Register'}
                </Button>
            </Form>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerUser: (user) => dispatch(registerUser(user)),
        fetchUser: (uid) => dispatch(fetchUser(uid)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);