const initialState = {}
export const courseReducer = (state=initialState, action) => {
switch(action.type){
    case 'VIDEO_URL':
        return { ...state, url: action.url }
    case 'GET_STATUS':
        return { ...state, status: action.status};
    case 'GET_COURSES':
        return { ...state, list: action.list };
    default:
        break;
}
return state;
}

export default courseReducer;