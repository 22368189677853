import React from "react";
import DeleteUser from './DeleteUser'
import { ProgressBar } from "react-bootstrap";
import EditUser from "./EditUser";


const UserSummary = ({user, deleteUser, updateUser}) => {
  return(
    <tr className={`align-middle ${user.status && user.status.cyberSecurity && !user.status.cyberSecurity.completed && user.status.cyberSecurity.retake?'table-danger':user.status && user.status.cyberSecurity && user.status.cyberSecurity.completed?'table-success':''}`}>
      <td>{user.email}</td>
      <td>{user.firstName}</td> 
      <td>{user.lastName}</td>
      <td>{user.managerEmail}</td>
      <td>{user.startDate}</td>
      <td>
        {(user.status && user.status.cyberSecurity ? user.status.cyberSecurity.score && user.status.cyberSecurity.score['11']? 12 : (user.status.cyberSecurity.currentModule || 0) : 0)+'/12'}
        <ProgressBar variant={user.status &&user.status.cyberSecurity && user.status.cyberSecurity.completed? 'success' : 'primary'}
        now={user.status && user.status.cyberSecurity ? user.status.cyberSecurity.score && user.status.cyberSecurity.score['11']? 100 : (user.status.cyberSecurity.currentModule-1 || 0)*100/11 : 0} />
      </td>
      <td>
        <EditUser user={user} updateUser={updateUser}/>
        <DeleteUser user={user} deleteUser={deleteUser}/>
      </td>
    </tr>
  );
}

export default UserSummary;