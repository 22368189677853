import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { reset, clearError } from '../../store/actions/authActions'
import { Container , Form, Button, Alert, Spinner, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import bg from '../../assets/bg.jpg'
import logo from "../../assets/Logo.png"

const Reset =(props) => {
  const [disabled,setDisabled] = useState(false);
  const [error,setError]  = useState(props.resetError);
  const [email,setEmail] = useState('')
  const navigate = useNavigate();

  useEffect(()=>{
    setError(props.resetError);
    if(props.resetError){
      setDisabled(false);
    }
  },[props.resetError])

  const clearError = (e) => {
    setError('')
    props.clearError();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    clearError();
    props.reset(email);
    setDisabled(false);
    setEmail('');
  }

  return (
    <Row style={{width:'100%',height:'100%'}}>
      <Col xs={7} className='d-none d-md-block'>
        <Container style={{width:'100%', height:'100%', padding:0, margin:0}}>
          <Image src={bg} style={{height:'100%', width:'100%'}} />
        </Container>
      </Col>
      <Col className='d-flex align-items-center justify-content-center vh-100'>
        <Container style={{textAlign: 'center'}}>
          <Image className='m-3' src={logo} width={100} height={100} />
          <h3 className='grey-text text-darken-3'>Reset Password</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e)=> setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className='mb-3' controlId="errorMessage">
              <Form.Text id='error'>
                {error ?
                  <Alert key='error' variant='danger'>
                    <div className='d-flex justify-content-between'>
                      <span>
                        {error}
                      </span>
                      <span onClick={clearError}>
                        X
                      </span>
                    </div> 
                  </Alert> : null}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Button disabled={disabled} id="reset" variant="primary" type="submit" className='m-1'>
                {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Reset'}
              </Button>
              <Button variant="primary" onClick={()=> navigate('/signin')}>
                Sign In
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    resetError: state.auth.resetError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (email) => dispatch(reset(email)),
    clearError: () => dispatch(clearError())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Reset);
