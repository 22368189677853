const initialState={
    message: ''
  }
  
  export const groupsReducer = (state=initialState, action) => {
    switch(action.type){
      case 'GROUP_ERROR':
        return{
          ...state,
          groupError: action.err.message
        }
      case 'GET_GROUPS':
        return action.groups;
      case 'CREATE_GROUP':
        return {...state, message:action.message};
      case 'GET_GROUP':
        return action.group;
      case 'DELETE_GROUP':
        return state;
      case 'CLEAR_GROUP':
        return {};
      default:
        break;
    }
    return state;
  }
  
  export default groupsReducer;