import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { signIn, clearError } from '../../store/actions/authActions'
import { Container , Form, Button, Alert, Spinner, Nav, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import bg from '../../assets/bg.jpg'
import logo from "../../assets/Logo.png"

const SignIn =(props) => {
  const [disabled,setDisabled] = useState(false);
  const [error,setError]  = useState(props.authError);
  const navigate = useNavigate();
  const [credentials,setCredentials] = useState({
    email:'',
    password: ''
  })

  useEffect(()=>{
    setError(props.authError);
    if(props.authError){
      setDisabled(false);
    }
  },[props.authError])

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.id]: e.target.value
    })
  }

  const clearError = (e) => {
    setError('')
    props.clearError();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    clearError();
    setDisabled(true);
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute('6LfMgegpAAAAAGqxpNpkPi4WRzIwIiOxenNND8Ir', {action: 'submit'}).then(function(token) {
        credentials.captcha = token; // Add the token to the credentials object
        props.signIn(credentials);
      });
    });
    setCredentials({
      ...credentials,
      password: ''
    })
  }

  return (
    <Row style={{width:'100%',height:'100%'}}>
      <Col xs={7} className='d-none d-md-block'>
        <Container style={{width:'100%', height:'100%', padding:0, margin:0}}>
          <Image src={bg} style={{height:'100%', width:'100%'}} />
        </Container>
      </Col>
      <Col className='d-flex align-items-center justify-content-center vh-100'>
        <Container style={{textAlign: 'center'}}>
          <Image className='m-3' src={logo} width={100} height={100} />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" placeholder="Enter email" value={credentials.email} onChange={handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control required type="password" placeholder="Password" value={credentials.password} onChange={handleChange}/>
            </Form.Group>

            <Form.Group className='mb-3' controlId="errorMessage">
              <Form.Text id='error'>
                {error ?
                  <Alert key='error' variant='danger'>
                    <div className='d-flex justify-content-between'>
                      <span>
                        {error}
                      </span>
                      <span onClick={clearError}>
                        X
                      </span>
                    </div> 
                  </Alert> : null}
              </Form.Text>
            </Form.Group>
            <Form.Group>
            <Button disabled={disabled} id="sign-in" variant="primary" type="submit">
              {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Sign In'}
            </Button>
            <br/>
            <Button variant="link" id="forgot" onClick={()=> navigate('/reset')}>
              Forgot Password?
            </Button>
            </Form.Group>
          </Form>
        </Container>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    clearError: () => dispatch(clearError())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SignIn);
