import { Container, Spinner, ListGroup, Col, Row } from "react-bootstrap"
import VideoPage from "./VideoPage";
import QuizPage from "./QuizPage";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setStatus, getStatus } from "../../store/actions/courseActions";
import { useParams } from "react-router-dom";
import { getCourse } from "../../store/actions/courseActions";

const CourseContent = (props) => {
    const { id } = useParams();
    const { uid } = props.auth.user;
    const [loading,setLoading] = useState(true)
    const [currentModule,setCurrentModule] = useState(-1)
    useEffect(()=>{
        props.getCourse(id)
    },[id])
    const increaseModuleCount = () => {
        if(props.courses.status.currentModule===currentModule)
            props.setStatus(uid,id,{...props.courses.status, currentModule:currentModule+1 })
        setCurrentModule(currentModule+1) 
    }
    const increaseModuleWithScores = (score) => {
        if(props.courses.status.currentModule===currentModule)
            props.setStatus(uid,id,{...props.courses.status, currentModule:currentModule+1, score:{
                ...props.courses.status.score , [currentModule]: score
            }})
        setCurrentModule(currentModule+1)
    } 
    useEffect(()=>{
        if(currentModule >= 0 && props.courses.list && props.courses.list[id] && Object.keys(props.courses.list[id].modules).length>0)
            setLoading(false)
    },[currentModule,props.courses.list])
    useEffect(()=>{
        if(props.courses.status){
            props.courses.status.currentModule ? setCurrentModule(props.courses.status.currentModule) : setCurrentModule(0)
        } else {
            props.getStatus(uid,id)
        }
    },[props.courses.status])
    if(!loading){
        return(
            <Container>
                <Row>
                    <Col className="order-md-2">
                        {props.courses.list[id].modules[currentModule].type === 'video' ? <VideoPage increaseCount={increaseModuleCount} count={currentModule} /> : <QuizPage increaseCountWithScore={increaseModuleWithScores} count={currentModule} />}
                    </Col>
                    <Col md='auto' className="order-md-1">
                        <ListGroup>
                          {Object.keys(props.courses.list[id].modules).map((module,i)=> {
                            return(<ListGroup.Item active={i==currentModule} key={i} action disabled={props.courses.status ? i>props.courses.status.currentModule : i>0} onClick={()=>setCurrentModule(parseInt(i))}>
                                {props.courses.list[id].modules[i].title}
                            </ListGroup.Item>)
                          })}                             
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return (
          <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Container>
        );
      }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatus: (uid,id,status) => dispatch(setStatus(uid,id,status)),
        getStatus: (uid,id) => dispatch(getStatus(uid,id)),
        getCourse: (id) => dispatch(getCourse(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CourseContent);