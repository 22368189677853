import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteGroup = ({group, id, deleteGroup}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return(
    <>
      <span className="btn btn-danger m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-trash"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Group?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {group.name}</p>
          <p>Description: {group.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={()=> {deleteGroup(group,id)}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteGroup;