import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row, ProgressBar } from "react-bootstrap";
import Question from "./Question";
import { setStatus } from "../../store/actions/courseActions";
import { fetchUser } from '../../store/actions/usersActions';
import certificate from './certificate.jpg';
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";

const QuizPage = (props) => {
  const { id } = useParams();
  const { uid } = props.auth.user
  const [name,setName] = useState('')
  const [finishDate,setFinishDate] = useState('')
  const [expiry,setExpiry] = useState('')
  const [imgUrl, setImgUrl] = useState(null);
  const [score, setScore] = useState(props.courses.status.completed? parseFloat(props.courses.status.score['11']) :parseFloat(0));
  const title = props.courses.list[id].modules[props.count].title;
  const date = new Date();
  useEffect(()=>{
    props.fetchUser(uid);
  },[uid])
  useEffect(()=>{
    if(props.users){
      setName(props.users.firstName+' '+props.users.lastName);
      setFinishDate(props.courses.status.dateCompleted)
      setExpiry(props.courses.status.validThrough)
    }
  },[props.users])
  useEffect(()=>{
    if(imgUrl) {
      const pdf = new jsPDF('landscape', 'pt', 'a4');
      pdf.addImage(imgUrl, "JPEG", 20, 20, 800, 550);
      pdf.save("certificate.pdf");
    }
  },[imgUrl])
  const generateImage = async (name, startDate, endDate) => {
    const img = new Image();
    img.src = certificate;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      ctx.font = '140px Italianno';
      ctx.fillStyle = 'darkblue';
      ctx.textAlign = 'center';
      ctx.fillText(name, 800, 615);
      ctx.font = '40px arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.fillText(startDate, 450, 860);
      ctx.fillText(endDate, 1125, 860);
      const dataUrl = canvas.toDataURL();
      setImgUrl(dataUrl);
    };
  };

  const handleShow = () => {
    generateImage(name,finishDate,expiry)
  }

  const finishCourse = () => {
    const finishDate = date.getDate()+"-"+date.toLocaleString('default',{month: 'short'})+"-"+ date.getFullYear()
    const expiry = date.getDate()+"-"+date.toLocaleString('default',{month: 'short'})+"-"+(date.getFullYear()+1)
    if(!props.courses.status.completed){
      props.setStatus(uid,id,{...props.courses.status, 
        currentModule:11,
        completed: true,
        dateCompleted: finishDate,
        validThrough: expiry,
        score:{
        ...props.courses.status.score , 11: score
        }
      })
    }
    generateImage(name,finishDate,expiry);
  };

  const quizList = props.courses.list[id].modules[props.count].questions;
  const [qno,setQno] = useState(props.courses.status.completed? Object.keys(quizList).length : 0);
  const increaseScore = () => {
      setScore(score+parseFloat(100/Object.keys(quizList).length))
  }
  const nextQuestion = () => {
      setQno(qno+1)
  }
  const retake = () => {
    setQno(0)
    setScore(0)
    if(props.count===11){
      props.setStatus(uid,id,{ 
        retake: true,
        currentModule:0,
      })
    }
  }
  const calculateProgress = () => {
      return (qno)*100 / Object.keys(quizList).length;
  }
return(
  <Container>
    {!(props.courses.status && props.courses.status.completed)?
    <>
    <h1>{title} {qno < Object.keys(quizList).length ? qno+1 : qno} / {Object.keys(quizList).length}</h1>
    <ProgressBar now={calculateProgress()} /> </>: ''
    }
    <Col>
      <Row>
          {
              qno<Object.keys(quizList).length ? <Question key={qno} question={quizList[qno]} score={score} increaseScore={increaseScore} nextQuestion={nextQuestion}/> : score<80 ? <><h3>Score: {score}% - Failed</h3> <Col xs="auto"> <Button onClick={retake}>Retake</Button> </Col> </>: ''
          }
      </Row>
      <Row className="justify-content-center">
      {
        (props.count<11 && score>80 && qno===Object.keys(quizList).length) ?
          <>
          <h3>Score: {score}% - Passed</h3>
          <Col xs='auto'>
          <Button className='m-1' variant="success" onClick={()=> props.increaseCountWithScore(score)}>
              Finish And Next
          </Button></Col></> : props.count===11 && score>80 && qno===Object.keys(quizList).length && !props.courses.status.completed?
          <><h3>Score: {score}% - Passed</h3>
          <Col xs='auto'>
          <Button className='m-1' variant="success" onClick={()=>finishCourse(score)}>
              Finish
          </Button></Col></> : ''
      }
      {props.courses.status.completed ?
        <Col xs='auto'> 
        <Button className='m-1' variant="success" onClick={handleShow}>
          Generate Certificate
        </Button></Col>: ''}
      </Row>
      {imgUrl ? <img src={imgUrl} style={{width: '100%', height: 'auto'}} /> : ''}
    </Col>
  </Container>);
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return{
    setStatus:(uid, id, status) => dispatch(setStatus(uid, id, status)),
    fetchUser: (uid) => dispatch(fetchUser(uid)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(QuizPage);