import { Container, Button, Table, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { filterGroups, fetchGroups, deleteGroup } from "../../store/actions/groupsActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import GroupSummary from "./GroupSummary";

const GroupsPage = (props) => {
  const keys = props.groups ? Object.keys(props.groups) : []
  const [search,setSearch] = useState('');
  useEffect(()=>{
    if(search.length){
      props.filterGroups(search)
    } else {
      props.fetchGroups()
    }
  },[search])
  
  return(
    <Container>
      <Row>
        <Col>
          <h2 className="d-none d-md-block"> GROUPS ({props.groups && Object.keys(props.groups).length || 0}) </h2>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <NavLink className="m-1" to='/group/create'>
            <Button variant="primary">
              Add Group 
            </Button>
          </NavLink>
          <Form.Group as={Row} className="m-1" controlId="firstName">
            <Col>
              <Form.Control placeholder="search" onChange={(e)=>setSearch(e.target.value)} value={search} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Table bordered responsive id="GroupsTable">
        <thead>
          <tr className="allign-middle table-primary">
            <th className="text-center">Name</th>
            <th className="text-center">Description</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={3}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(id => {
            return(
              <GroupSummary key={id} id={id} group={props.groups[id]} deleteGroup={props.deleteGroup}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterGroups: (search) => dispatch(filterGroups(search)),
    fetchGroups: () => dispatch(fetchGroups()),
    deleteGroup: (group,id) => dispatch(deleteGroup(group,id))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(GroupsPage);