import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAdmin = (props) => {
  const location = useLocation();
  if(props.auth.category ==='INVITEE') {
    return <Navigate to='/register' state={{from: location}} />
  }
  return <Outlet />
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(RequireAdmin);