import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { GiCyberEye } from "react-icons/gi";
import CardIcon from './CardIcon';
import { NavLink } from "react-router-dom";
import { getCourses } from "../../store/actions/courseActions";

const CoursePage = (props) => {
  const [search,setSearch] = useState('');
  const [courses,setCourses] = useState({});
  useEffect(()=>{
    props.getCourses(search);
  },[search])
  useEffect(()=>{
    setCourses(props.courses.list || {});
  },[props.courses.list])
  return (
    <Container className="p-3">
      <Row>
        <Col>
          <h2 className="d-none d-md-block"> COURSES ({props.courses && props.courses.list && Object.keys(props.courses.list).length || 0}) </h2>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <NavLink className="m-1" to='/courses/create'>
            <Button variant="primary">
              Add Course 
            </Button>
          </NavLink>
          <Form.Group as={Row} className="m-1" controlId="firstName">
            <Col>
              <Form.Control placeholder="search" onChange={(e)=>setSearch(e.target.value)} value={search} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {Object.keys(courses).map((course,i) => (
          <CardIcon to={`/course/${courses[course].id}`} title={courses[course].name} key={i}>
            <GiCyberEye />
          </CardIcon>
        ))}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCourses: (search) => dispatch(getCourses(search))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CoursePage);