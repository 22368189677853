import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { getVideoURL } from "../../store/actions/courseActions"
import { useParams } from "react-router-dom";

const VideoPage = (props) => {
  const { id } = useParams();
  const videoRef = useRef();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [lastTime, setLastTime] = useState(0);
  const [loading,setLoading] = useState(true)
  const [url,setUrl] = useState({})
  const [title,setTitle] = useState('')
  const [progress,setProgress] = useState(0)
  useEffect(()=>{
    if(props.courses && Object.keys(props.courses.list[id].modules).length)
      setLoading(true);
      props.getVideoURL(props.courses.list[id].modules[props.count].file)
      setTitle(props.courses.list[id].modules[props.count].title)
  },[props.count, props.courses.list[id].modules])
  useEffect(()=>{
    if(props.courses.url){
      setUrl(props.courses.url)
      setLastTime(0);
      setProgress(0);
      setLoading(false);
    }
  },[props.courses.url])
  const handleTimeUpdate = (e) => {
    if (videoRef.current.currentTime - lastTime > 1.5) {
      videoRef.current.currentTime = lastTime;
    } else {
      setLastTime(videoRef.current.currentTime);
      setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
    }
  };
  if(!loading){
    return(
      <Container style={{textAlign:'center'}}>
        <Row className="text-center">
          <Col className="text-center">
            <h3>{title}</h3>
          </Col>
        </Row>
        <Col>
          <video 
          autoPlay
          muted={false}
          key={url['.webm']} 
          height={'100%'} 
          width={'100%'} 
          controls 
          controlsList="nodownload" 
          ref={videoRef}
          onTimeUpdate={handleTimeUpdate}>
            {isSafari ? <source src={url['.mp4']} type="video/mp4" /> : 
              <source src={url['.webm']} type="video/webm" /> }
              Your browser does not support the video tag.
          </video>
          <Row className="justify-content-center">
          {
            props.count<11 && progress>99.8?
              <Col xs="auto">
                <Button className='m-1' variant="success" onClick={()=> props.increaseCount()}>
                    Finish And Next
                </Button>
              </Col>: ''
          }
          </Row>
        </Col>
      </Container>);
  } else {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVideoURL: (video) => dispatch(getVideoURL(video)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(VideoPage);