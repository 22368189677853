import { Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const CardIcon = (props) => {
  return(
    <Card style={{ width: '16rem',padding: '2px'}} className='m-1'>
      <NavLink to={props.to} >
        <center>
          <svg xmlns="http://www.w3.org/2000/svg" width="8rem" height="8rem" viewBox="0 0 16 16">
            {props.children}
          </svg>
          <h4 className='m-1' style={{fontFamily:'Open Sans', color:'black', fontSize:'16px' }}>{props.title}</h4>
        </center>
      </NavLink>
    </Card>);
}

export default CardIcon;