import { initializeApp } from 'firebase/app'
import { connectDatabaseEmulator, getDatabase } from 'firebase/database'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'abc',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'localhost',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'traces-lms',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseConfig2 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'abc',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'localhost',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'traces-lms',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID2,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID2
};

if (process.env.NODE_ENV==='development'){
  firebaseConfig.databaseURL = 'http://127.0.0.1:9000/?ns=traces-lms';
  firebaseConfig2.databaseURL = 'http://127.0.0.1:9000/?ns=traces-lms';
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const app2 = initializeApp(firebaseConfig2, 'Authenticator');
const db = getDatabase(app);
const auth = getAuth(app);
const auth2 = getAuth(app2);
const functions = getFunctions(app);
const functions2 = getFunctions(app2);
const storage = getStorage(app);

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth,'http://localhost:9099')
  connectAuthEmulator(auth2,'http://localhost:9099')
  connectDatabaseEmulator(db,'localhost','9000')
  connectFunctionsEmulator(functions, 'localhost','5001')
  connectFunctionsEmulator(functions2, 'localhost','5001')
  connectStorageEmulator(storage,'localhost','9199')
}

export { db , auth, auth2, functions, functions2, storage };

