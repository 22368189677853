import React from "react";
import { Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";

const EmployeeLinks = (props) => {
  return(
  <>
    <Nav.Link as={Link} to='/'>Dashboard</Nav.Link>
    <Nav.Link as={Link} to='/courses'>Courses</Nav.Link>
  </>
  );
}

export default EmployeeLinks;