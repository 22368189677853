const initialState={
  message: ''
}

export const usersReducer = (state=initialState, action) => {
  switch(action.type){
    case 'USER_ERROR':
      return{
        ...state,
        userError: action.err.message
      }
    case 'GET_USERS':
      return action.users;
    case 'CREATE_USER': case 'REGISTER_USER':
      return {...state, message:action.message};
    case 'GET_USER':
      return action.user;
    case 'DELETE_USER':
      return state;
    case 'CLEAR_USERS':
      return {};
    default:
      break;
  }
  return state;
}

export default usersReducer;