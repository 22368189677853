import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

const EditUser = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
  const [user,setUser] = useState(props.user);
  const handleChange = (e) => {
      setUser({ ...user, [e.target.id]: e.target.value });
  };

  return(
    <>
      <span className="btn btn-primary m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-pencil"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="firstName">
            <Form.Label>First Name:</Form.Label>
            <Form.Control required type="text" value={user.firstName} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control required type="text" value={user.lastName} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="managerEmail">
            <Form.Label>Reporting Manager Email:</Form.Label>
            <Form.Control required type="email" value={user.managerEmail} onChange={handleChange} />
          </Form.Group>
          {props.groups? 
          <Form.Group controlId="group">
            <Form.Label>Group:</Form.Label>
            <Form.Select id="group" onChange={handleChange} value={user.group}>
              <option value="">Select Group</option>
              {Object.keys(props.groups).map((gId) => {
                return(
                  <option key={gId} value={gId}>{props.groups[gId].name}</option>
                )
              })}
            </Form.Select>
          </Form.Group> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => {props.updateUser(user);handleClose()}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps,null)(EditUser);