import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAdmin = (props) => {
  const location = useLocation();
  if(props.auth.category==='SUPER_ADMIN') {
    return <Outlet />
  }
  return <Navigate to='/' state={{from: location}} />
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(RequireAdmin);