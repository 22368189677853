import { combineReducers } from "redux";
import { legacy_createStore as createStore, applyMiddleware } from '@reduxjs/toolkit'
import { thunk } from "redux-thunk";
import authReducer from "./reducers/authReducer";
import courseReducer from "./reducers/courseReducer"
import usersReducer from "./reducers/usersReducer";
import groupsReducer from "./reducers/groupsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    courses: courseReducer,
    users: usersReducer,
    groups: groupsReducer
})

const store = createStore(rootReducer,applyMiddleware(thunk))

export default store;