import React from "react";
import DeleteGroup from './DeleteGroup'


const GroupSummary = ({group, id, deleteGroup}) => {
  return(
    <tr className={`align-middle ${group.status && !group.status.completed && group.status.retake?'table-danger':group.status && group.status.completed?'table-success':''}`}>
      <td>{group.name}</td>
      <td>{group.description}</td> 
      <td>
        <DeleteGroup group={group} id={id} deleteGroup={deleteGroup}/>
      </td>
    </tr>
  );
}

export default GroupSummary;