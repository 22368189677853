import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import CardIcon from './CardIcon';
import { connect } from 'react-redux';
import { FcBusinessman, FcTreeStructure, FcReading } from 'react-icons/fc'

const Dashboard = (props) => { 
  const {category} = props.auth
  const[loading,setLoading] = useState(true)
  useEffect(()=>{
    if(category && category!=='LOGIN'){
      setLoading(false)
    }
  },[category])

  if(!loading){
    return(
      <Container className="p-3">
        <Row>
          {category==='ADMIN' ?
          <>
            <CardIcon to='/users' title="Users" icon="people">
              <FcBusinessman />
            </CardIcon>
          </> : null}
          <>
            <CardIcon to='/courses' title="Courses" icon="card-list">
              <FcReading />
            </CardIcon>
          </>
          {category==='SUPER_ADMIN' ?
          <>
            <CardIcon to='/users' title="Users" icon="people">
              <FcBusinessman />
            </CardIcon>
            <CardIcon to='/groups' title="Groups" icon="people">
              <FcTreeStructure />
            </CardIcon>
          </> : ''}
        </Row>
      </Container>
    )} else {
      return(
      <Container className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>);
    }
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(Dashboard)