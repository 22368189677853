import { Container, Button, Table, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { fetchUsers, deleteUser, updateUser } from "../../store/actions/usersActions";
import { fetchGroups } from "../../store/actions/groupsActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import UserSummary from "./UserSummary";

const UsersPage = (props) => {
  const keys = props.users ? Object.keys(props.users) : []
  const [search,setSearch] = useState('');
  const [status,setStatus] = useState('All');
  const [loading,setLoading] = useState(false);
  useEffect(()=>{
    if(search.length){
      props.fetchUsers(search,status);
    } else {
      props.fetchUsers('',status);
    }
    props.fetchGroups();
  },[search,status])
  const exportData = () => {
    setLoading(true);
    let csv = [];
    csv.push(['First Name','Last Name','Email','Reporting Manager','Start Date','Progress','Status'])
    const keys = Object.keys(props.users);
    keys.forEach(uid => {
      const user = props.users[uid];
      const row = [
      user.firstName,
      user.lastName,
      user.email,
      user.reportingManager,
      user.startDate,
      user.status && user.status.cyberSecurity ? user.status.cyberSecurity.currentModule : 0,
      user.status && user.status.cyberSecurity ? user.status.cyberSecurity.completed ? 'PASS' : user.status.cyberSecurity.repeat ? 'FAIL' : 'IN PROGRESS' : 'NOT STARTED',
      ];
      csv.push(row.join(','));
    });
    let csvContent = "data:text/csv;charset=utf-8,";
    csv.forEach(function(row){
      csvContent += row + "\r\n";
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  }
  return(
    <Container>
      <Row>
        <Col className="m-1 d-flex">
          <h2> USERS ({props.users && Object.keys(props.users).length || 0}) </h2>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <NavLink className="m-1" onClick={exportData}>
            <Button variant="primary">
              {loading? <Spinner animation="border" size='sm' role="status" /> : 'Export' }
            </Button>
          </NavLink>
          <NavLink className="m-1" to='/user/create'>
            <Button variant="primary">
              Add User 
            </Button>
          </NavLink>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <Form.Group as={Row} className="m-1" controlId="firstName">
            <Col>
              <Form.Control as="select" placeholder="search" onChange={(e)=>setStatus(e.target.value)} value={status}>
                <option value='ALL'>All</option>
                <option value='NOT STARTED'>Not Started</option>
                <option value='IN PROGRESS'>In Progress</option>
                <option value='PASS'>Pass</option>
                <option value='FAIL'>Fail</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="m-1" controlId="firstName">
            <Col>
              <Form.Control placeholder="search" onChange={(e)=>setSearch(e.target.value)} value={search} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Table bordered responsive id="UsersTable">
        <thead>
          <tr className="allign-middle table-primary">
            <th className="text-center">User Email</th>
            <th className="text-center">First Name</th>
            <th className="text-center">Last Name</th>
            <th className="text-center">Reporting Manager</th>
            <th className="text-center">Start Date</th>
            <th className="text-center">Progress</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? <tr><td colSpan={4}>'No Records Available'</td></tr>: ''}
          {keys && keys.map(uid => {
            return(
              <UserSummary key={uid} user={props.users[uid]} deleteUser={props.deleteUser} updateUser={props.updateUser}/>
            );
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (search, status) => dispatch(fetchUsers(search, status)),
    deleteUser: (uid) => dispatch(deleteUser(uid)),
    updateUser: (user) => dispatch(updateUser(user)),
    fetchGroups: () => dispatch(fetchGroups()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UsersPage);